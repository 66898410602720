/**
 * Author: www.cxagroup.com
 * Developed by Haibin Dai on 2022/9/27.
 */
import MediaStorage from "@/apps/octopus/entities/MediaStorage";

export default {
  namespaced: true,
  state: {
    storage: {} as MediaStorage,
  },
  mutations: {
    setStorage(state: {storage: MediaStorage}, storage: MediaStorage) {
      state.storage = storage;
      sessionStorage.setItem("OCTOPUS_REQUEST_STORAGE", JSON.stringify(storage));
    }
  },
  actions: {},
  getters: {
    storage: (state: {octopus: {storage: MediaStorage}}) => {
      return state.octopus.storage;
    }
  },
};

/**
 * Author: www.binarilly.com
 * Developed by Haibin Dai on 2022/9/26.
 */

export default function FileSizeDisplayFormat(size: number, fractionDigits = 2, unit = "MB") {
  let value = size;
  if (unit === "MB") {
    value = size / 1024.0 / 1024.0;
  }
  return value.toFixed(fractionDigits) + unit;
}

import {RouteConfig} from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/octopus",
    name: "octopus-index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/OctopusIndex.vue"),
    children: [
      {
        path: "audio",
        name: "octopus-audio",
        component: () => import("../views/OctopusAudio.vue"),
      },
      {
        path: "download/:storageId",
        name: "octopus-download-storage",
        component: () => import("../views/OctopusDownload.vue"),
      },
      {
        path: "download",
        name: "octopus-download",
        component: () => import("../views/OctopusDownload.vue"),
      },
    ],
  }
];

export default routes;
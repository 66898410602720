import Vue from "vue";
import {get} from "lodash";
import WindowDocument from "@/entities/WindowDocument";

const setSize = function(vue: Vue, window: any) {
  const windowDocument: WindowDocument = {
    width: window.innerWidth,
    height: window.innerHeight,
    document: {width: get(document.getElementById("app"), "clientWidth", 0)},
  };
  vue.$store.dispatch("dispatchWindowDocument", windowDocument).then();
}

export const setWindowDocument = (vue: Vue) => {
  setSize(vue, window);
  window.onresize = () => {
    setSize(vue, window);
  };
};

export const setWindowScrollDown = (vue: Vue) => {
  document.onscroll = () => {
    return vue.$store.dispatch("dispatchDocumentScrollTop", document.documentElement.scrollTop);
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import WindowDocument from "@/entities/WindowDocument";
import octopus from "@/store/modules/octopus";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    windowDocument: {width: 0, height: 0, document: {width: 0}, } as WindowDocument,
    scroll: {top: 0},
  },
  mutations: {
    setWindowDocument: (state: {windowDocument: WindowDocument}, windowDocument: WindowDocument) => {
      state.windowDocument = windowDocument;
    },
    setDocumentScrollTop: (state: {scroll: {top: number}}, scrollTop: number) => {
      state.scroll.top = scrollTop;
    }
  },
  actions: {
    dispatchWindowDocument: async (context: any, windowDocument: WindowDocument) => {
      context.commit("setWindowDocument", windowDocument);
    },
    dispatchDocumentScrollTop: async (context: any, scrollTop: number) => {
      context.commit("setDocumentScrollTop", scrollTop);
    },
  },
  modules: {
    octopus,
  },
  getters: {
    windowDocument: (state: {windowDocument: WindowDocument}) => state.windowDocument,
    scroll: (state: {scroll: {top: number}}) => state.scroll,
  },
})
